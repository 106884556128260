// Outlet
.outletDetails {
	.details {
		.clearfix;
		margin-bottom: 45px;
		.magasins {
			display: inline-block;
			margin: 0;
			padding: 2px 8px;
			background-color: @brand-primary;
			color: #f1f1f1;
			font-size: 1rem;
			line-height: 1.5em;
		}
		.imgWrapper {
			position: relative;
		}
		.price {
			position: absolute;
			top: 0;
			left: 0;
			padding: 10px 12px;
			background-color: @brand-primary;
			color: #fff;
			font-size: 1.25rem;
			line-height: 1.3em;
			text-align: center;
			.promo {
				color: #ccc;
				text-decoration: line-through;
			}
		}
	}
}