@-webkit-keyframes bounce {
	0%,20%,50%,80%,100% {
		-webkit-transform:translateY(0);
	}
	
	40% {
		-webkit-transform:translateY(-30px);
	}
	
	60% {
		-webkit-transform:translateY(-15px);
	}
}

@-moz-keyframes bounce {
	0%,20%,50%,80%,100% {
		-moz-transform:translateY(0);
	}
	
	40% {
		-moz-transform:translateY(-30px);
	}
	
	60% {
		-moz-transform:translateY(-15px);
	}
}

@-ms-keyframes bounce {
	0%,20%,50%,80%,100% {
		-ms-transform:translateY(0);
	}
	
	40% {
		-ms-transform:translateY(-30px);
	}
	
	60% {
		-ms-transform:translateY(-15px);
	}
}

@-o-keyframes bounce {
	0%,20%,50%,80%,100% {
		-o-transform:translateY(0);
	}
	
	40% {
		-o-transform:translateY(-30px);
	}
	
	60% {
		-o-transform:translateY(-15px);
	}
}

@keyframes bounce {
	0%,20%,50%,80%,100% {
		transform:translateY(0);
	}
	
	40% {
		transform:translateY(-30px);
	}
	
	60% {
		transform:translateY(-15px);
	}
}

.bounce {
	.animation-name(bounce);
}