@-webkit-keyframes scaleOutBig {
	0% {
		opacity:1;
	}
	
	20% {
		opacity:1;
		-webkit-transform:scale(.50);
	}
	
	100% {
		opacity:0;
		-webkit-transform:scale(10);
	}
}

@-moz-keyframes scaleOutBig {
	0% {
		opacity:1;
	}
	
	20% {
		opacity:1;
		-moz-transform:scale(.50);
	}
	
	100% {
		opacity:0;
		-moz-transform:scale(5);
	}
}

@-ms-keyframes scaleOutBig {
	0% {
		opacity:1;
	}
	
	20% {
		opacity:1;
		-ms-transform:scale(.50);
	}
	
	100% {
		opacity:0;
		-ms-transform:scale(5);
	}
}

@-o-keyframes scaleOutBig {
	0% {
		opacity:1;
	}
	
	20% {
		opacity:1;
		-o-transform:scale(.50);
	}
	
	100% {
		opacity:0;
		-o-transform:scale(5);
	}
}

@keyframes scaleOutBig {
	0% {
		opacity:1;
	}
	
	20% {
		opacity:1;
		transform:scale(.50);
	}
	
	100% {
		opacity:0;
		transform:scale(5);
	}
}

.scaleOutBig {
	.animation-name(scaleOutBig);
}