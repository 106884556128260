// Magasin
.magasinDetails {
	#block-views-bloc-point-de-vente-block {
		&, .view-bloc-point-de-vente, .view-content, .ip-geoloc-map, #ip-geoloc-map-of-view-bloc_point_de_vente-block {
			@media (min-width: @screen-md-min) {
				height: 100% !important;
			}
		}
	}

	.coordonnees {
		.map, .address {
			padding: 0;
		}
		.address {
			padding-top: 40px;
			padding-bottom: 40px;
			background-color: #f6f6f6;
			> .row {
				margin: 0;
				max-width: 700px;
			}
			div[class*="col-"] {
				padding-left: 50px;
				@media (max-width: @screen-md-max) {
					padding-left: 15px;
				}
				@media (max-width: @screen-sm-max) {
					padding-left: 50px;
				}
				@media (max-width: @screen-xs-max) {
					padding-left: 15px;
					&:nth-child(2) {
						margin-top: 30px;
					}
				}
			}
			h2 {
				margin: 0 0 20px;
				color: #424242;
				font-size: 1.5rem;
				font-weight: 700;
				line-height: normal;
			}
			p {
				margin: 0 0 25px;
				color: #424242;
				font-size: 1rem;
				line-height: 1.56em;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
			ul {
				.customListCheck;
			}
			a.btn {
				margin-top: 15px;
				padding: 18px 30px;
				font-weight: 400;
			}
		}
	}

	.horaires {
		padding-top: 50px;
		padding-bottom: 50px;
		h2 {
			margin: 0 0 25px;
			color: #424242;
			font-size: 1.5rem;
			font-weight: 700;
			line-height: normal;
		}
		h3 {
			margin: 0;
			color: @brand-primary;
			font-size: 1.13rem;
			font-weight: 700;
			line-height: 1.44em;
			text-transform: uppercase;
		}
		p {
			color: #444444;
		}
		@media (max-width: @screen-sm-max) {
			div[class*="col-"] {
				& + div[class*="col-"] {
					margin-top: 30px;
				}
			}
		}
	}

	.contentText {
		margin: 20px 0;
		padding: 55px 0;
		border-top: 1px solid #d0cfce;
		border-bottom: 1px solid #d0cfce;
		color: #3c3c3c;
		font-size: 1.13rem;
		line-height: 1.67em;
	}

	.equipe {
		h2 {
			color: #000;
		}
	}
}