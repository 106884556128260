body.page-actualites,
body.page-ou-nous-trouver,
body.page-conseils,
body.page-inspiration,
body.page-outlet {
	#main {
		padding-bottom: 0;
	}
	.view-header {
		margin-bottom: 60px;
		p {
			color: #7d7d7d;
			font-size: 1.13rem;
			line-height: 1.67em;
		}
		.btn {
			padding: 16px 50px;
			font-size: 1.5rem;
			line-height: 1em;
			@media (max-width: @screen-sm-max) {
				font-size: 1rem;
			}
		}
		div[class*="col-"] {
			&:nth-child(2) {
				text-align: right;
				@media (max-width: @screen-sm-max) {
					margin-top: 30px;
					text-align: left;
				}
			}
		}
	}
	.viewWrapper {
		padding-top: 30px;
		padding-bottom: 150px;
		&.bg-gray {
			background-color: #f1f1f1;
		}
	}
}

.view-filters:not(.mapFilters) {
	.clearfix;
	margin-top: 30px;
	margin-bottom: 30px;
	.viewFiltersTitle {
		position: absolute;
		margin-top: 24px;
		margin-bottom: 34px;
		color: #2a2a2a;
		font-weight: 700;
		@media (max-width: @screen-sm-max) {
			position: relative;
			margin-bottom: 15px;
		}
	}
	.form-type-select {
		.form-select {
			height: auto;
			width: 100%;
		    padding: 0;
		    background-color: transparent;
		    border: 0;
		    border-radius: 0;
		    -webkit-box-shadow: none;
		    -moz-box-shadow: none;
		    box-shadow: none;
		}
		.form-control, output, option {
			color: #000;
			font-size: 0.88rem;
		    font-weight: 700;
		    line-height: 1.43em;
		    text-transform: uppercase;
		}
	}
	#edit-c-wrapper, #edit-cat-wrapper {
		float: right;
		@media (max-width: @screen-xs-max) {
			float: none;
		}
	}
	#edit-t-wrapper {
		float: left;
		margin: 32px 0;
		@media (max-width: @screen-xs-max) {
			float: none;
		}
	}
	.form-type-bef-link {
		margin: 0;
		float: left;
		@media (max-width: @screen-xs-max) {
			font-size: 0;
		}
		a {
			display: block;
			padding: 10px 0;
			border-bottom: 3px solid transparent;
			color: #2a2a2a;
			font-size: 0.88rem;
			font-weight: 700;
			line-height: 1.43em;
			text-decoration: none;
			text-transform: uppercase;
			.transition(all .2s);
			@media (max-width: @screen-xs-max) {
				display: inline-block;
				border-bottom: 0;
			}
			&:hover, &.active {
				border-bottom-color: @brand-primary;
				color: @brand-primary;
			}
		}
		& + .form-type-bef-link {
			margin-left: 20px;
			@media (max-width: @screen-xs-max) {
				margin-left: 5px;
				&:before {
					content: '-';
					display: inline-block;
					margin-right: 5px;
					color: #2a2a2a;
					font-size: 0.88rem;
					font-weight: 700;
					line-height: 1.43em;
				}
			}
		}
	}
	&.bordered {
		border-bottom: 1px solid rgba(0,0,0,.1);
		.form-type-bef-link {
			a {
				padding: 30px 0;
				@media (max-width: @screen-xs-max) {
					padding: 10px 0;
				}
			}
		}
	}
}
.mapContainer {
	position: relative;
	margin-bottom: 60px;
	overflow: hidden;
	.mapFiltersWrapper {
		position: absolute;
		z-index: 5;
	    bottom: 0;
	    left: 0;
	    height: 0;
	    width: 100%;
	    .container {
			position: relative;
			height: 0;
			.mapFilters {
				position: absolute;
				bottom: 0;
				right: 0;
			}
	    }
	}
}

.mapFilters {
	box-shadow: 0px 0px 98px 0px rgba(0, 0, 0, 0.15);
	width: 475px;
	max-width: 100%;
	label:not(.control-label) {
		display: block;
		position: relative;
		margin: 0;
		padding: 15px 25px;
		background-color: #f3f3f3;
		color: #2a2a2a;
		font-size: 1.13rem;
		font-weight: 500;
		line-height: 1.67em;
		@media (max-width: @screen-sm-max) {
			&:before {
				content: "\f106";
				position: absolute;
				top: 50%;
				right: 15px;
				margin-top: -16px;
				color: #2a2a2a;
				font-family: "FontAwesome";
				font-size: 2rem;
				line-height: 1em;
				.transition(all .2s);
			}
			&.filterOpen {
				&:before {
					content: "\f107";
				}
			}
		}
		& + .views-widget {
			display: none;
			@media (min-width: @screen-md-min) {
				display: block !important;
				height: auto !important;
			}
		}
	}
	.form-type-radio {
		margin: 0;
		&:first-child {
			display: none;
		}
		label {
			display: block;
			position: relative;
			margin: 0;
			padding: 0;
			background-color: #fff;
			color: #7d7d7d;
			font-size: 1.13rem;
			font-weight: 400;
			line-height: 1.67em;
			.transition(all .2s);
			&:before {
				content: '';
				/* display: inline-block;
				vertical-align: middle;
				margin: 0 25px 0 0; */
				display: block;
				position: absolute;
				top: 23px;
				left: 30px;
				height: 24px;
				width: 24px;
				background-color: transparent;
				border: 3px solid #fff;
				border-radius: 50%;
				box-shadow: 0 0 0 1px #e6e6e6;
				.transition(all .2s);
			}
			input {
				display: none;
			}
			> span {
				display: block;
				padding: 20px 30px;
				padding-left: 79px;
			}
			&.active {
				&:before {
					background-color: @brand-primary;
				}
			}
			&:hover {
				background-color: #e5f6f7;
				&:before {
					border-color: #e5f6f7;
				}
			}
		}
		& + .form-type-radio {
			border-top: 1px solid #e5e5e5;
		}
	}
}

.viewSearch {
	.clearfix;
	margin: 26px 0 12px;
	.form-item-search-articles {
		margin: 0;
		float: left;
		width: calc(~"100% - 50px");
		.form-control {
			height: 50px;
			width: 100%;
			padding: 0 15px;
			background-color: #fff;
			border: 1px solid #a7a7a7;
			border-right: 0;
			border-radius: 5px 0 0 5px;
			-webkit-box-shadow: none;
			-moz-box-shadow: none;
			box-shadow: none;
		}
		.form-control, output {
			color: #000;
			font-size: 1rem;
			font-weight: 400;
			line-height: normal;
		}
		::-webkit-input-placeholder {
	      color: #000;
	    }
	    ::-moz-placeholder {
	      color: #000;
	    }
	    :-ms-input-placeholder {
	      color: #000;
	    }
	    :-moz-placeholder {
	      color: #000;
	    }
		.input-group-addon {
			display: none;
		}
	}
	.form-submit {
		float: left;
		display: block;
		padding: 10px;
		height: 50px;
		width: 50px;
		background-color: #fff;
		border: 1px solid #a7a7a7;
		border-left: 0;
		border-radius: 0 5px 5px 0;
		color: #878787;
		font-size: 1rem;
		font-weight: 400;
		line-height: 30px;
		text-align: center;
		&:hover {
			background-color: @brand-primary;
			border-color: #a7a7a7;
			color: #fff;
		}
	}
}

.inspirationMasonry {
	-moz-column-count: 4;
	-webkit-column-count: 4;
	column-count: 4;
	-moz-column-gap: 30px;
	-webkit-column-gap: 30px;
	column-gap: 30px;
	@media (max-width: @screen-sm-max) {
		-moz-column-count: 3;
		-webkit-column-count: 3;
		column-count: 3;
	}
	@media (max-width: @screen-xs-max) {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;
	}
	@media (max-width: 479px) {
		-moz-column-count: 1;
		-webkit-column-count: 1;
		column-count: 1;
	}
	.inspiration {
		padding-bottom: 30px;
	    -webkit-column-break-inside: avoid;
	    page-break-inside: avoid;
	    break-inside: avoid;
	}
	.inspirationShow {
		display: block;
		position: relative;
		background-color: @brand-primary;
	    overflow: hidden;
		img {
			position: relative;
			width: 100%;
			opacity: 1;
			.transition(all .35s);
		}
		.more {
			position: absolute;
			top: 100%;
			left: 0;
			height: 100%;
			width: 100%;
			.transition(all .35s);
			.moreBtn {
				position: absolute;
				top: 50%;
				margin-top: -24px;
				left: 50%;
				margin-left: -24px;
				&:hover {
					background-color: #fff;
					color: @brand-primary;
				}
			}
		}
		&:hover {
			img {
				opacity: .3;
				-webkit-filter: grayscale(1);
					filter: grayscale(1);
			}
			.more {
				top: 0;
			}
		}
	}
	.itemOverlay {
		display: none !important;
	}
}

.view {
	.more-link, .pager-load-more {
		clear: both;
		margin: 0;
		a {
			.btn;
			.btn-border--black;
		}
	}
}


.pager--infinite-scroll {
	margin: 0;
	li {
		display: block;
	}
	a {
		.btn !important;
		.btn-border--black !important;
	}
}