@-webkit-keyframes dropDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-30px);
	}
	
	70% {
		opacity: 1;
	}
	
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes dropDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(-30px);
	}
	
	70% {
		opacity: 1;
	}
	
	100% {
		opacity: 1;
		-moz-transform: translateY(0);
	}
}

@-ms-keyframes dropDown {
	0% {
		opacity: 0;
		-ms-transform: translateY(-30px);
	}
	
	70% {
		opacity: 1;
	}
	
	100% {
		opacity: 1;
		-ms-transform: translateY(0);
	}
}

@-o-keyframes dropDown {
	0% {
		opacity: 0;
		-o-transform: translateY(-30px);
	}
	
	70% {
		opacity: 1;
	}
	
	100% {
		opacity: 1;
		-o-transform: translateY(0);
	}
}

@keyframes dropDown {
	0% {
		opacity: 0;
		transform: translateY(-30px);
	}
	
	70% {
		opacity: 1;
	}
	
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.dropDown {
	.animation-name(dropDown);
}