// Footer
#newsletter {
	margin-top: -60px;
	.wrapper {
		.clearfix;
		position: relative;
		background-color: @brand-primary;
		.content {
			position: absolute;
			float: left;
			height: 100%;
			width: 65%;
			text-align: center;
			@media (max-width: @screen-sm-max) {
				width: 55%;
			}
			@media (max-width: @screen-xs-max) {
				position: relative;
				padding: 20px 15px;
				height: auto;
				width: 100%;
			}
			h2 {
				margin: 0;
				width: 100%;
				color: #fff;
				font-size: 2.81rem;
				font-weight: 300;
				line-height: 1em;
				@media (min-width: @screen-sm-min) {
					position: absolute;
					top: 50%;
					-webkit-transform: translate(0,-50%);
					-moz-transform: translate(0,-50%);
					-ms-transform: translate(0,-50%);
					-o-transform: translate(0,-50%);
					transform: translate(0,-50%);
				}
			}
		}
		a {
			display: block;
			position: relative;
			float: left;
			margin-left: 65%;
			padding: 43px 80px 43px 50px;
			width: 35%;
			background-color: fade(#292624, 10%);
			color: #fff;
			font-size: 1.5rem;
			font-weight: 500;
			line-height: normal;
			text-decoration: none;
			.transition(all .2s);
			@media (max-width: @screen-sm-max) {
				margin-left: 55%;
				width: 45%;
			}
			@media (max-width: @screen-xs-max) {
				margin: 0;
				padding: 30px 60px 30px 30px;
				width: 100%;
			}
			.fa {
				position: absolute;
				top: 50%;
				right: 50px;
				margin-top: -13px;
				font-size: 1.63rem;
				font-weight: 400;
				line-height: 1em;
			}
			&:hover {
				background-color: fade(#292624, 50%);
			}
		}
	}
}

#footer {
	// padding-top: 90px;
	padding-top: 1px;
	background-color: #dfdfdf;
	section.block {
		float: left;
	    padding-left: 15px;
	    padding-right: 15px;
		width: 33.33333333%;
		&:last-child {
			text-align: right;
		}
		@media (max-width: @screen-sm-max) {
			&:nth-child(1), &:nth-child(2) {
				width: 50%;
			}
			&:nth-child(3) {
				margin-top: 30px;
				width: 100%;
				text-align: center;
			}
		}
		@media (max-width: @screen-xs-max) {
			&:nth-child(1), &:nth-child(2) {
				width: 100%;
			}
			&:nth-child(2) {
				margin-top: 30px;
			}
		}
	}
	.infos {
		padding-top: 60px;
		padding-bottom: 35px;
		p, ul, ol {
			margin: 0 0 25px;
			color: fade(#070707, 90%);
			font-size: 0.88rem;
			line-height: 1.71em;
			a {
				color: fade(#070707, 90%);
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
		strong {
			font-weight: 500;
		}
		ul, ol {
			li {
				margin: 0;
				text-transform: uppercase;
				font-weight: 700;
				& + li {
					margin-top: 15px;
				}
			}
		}
		ul {
			padding: 0;
			list-style: none;
		}
		.social {
			display: inline-block;
			vertical-align: middle;
			position: relative;
			height: 58px;
			width: 58px;
			border-radius: 50%;
			color: #fff;
			font-size: 0;
			text-align: center;
			text-decoration: none;
			.transition(all .2s);
			&:before {
				content: '';
				font-family: 'FontAwesome';
				font-size: 1.56rem;
				font-weight: 400;
				line-height: 58px;
			}
			&.facebook {
				background-color: #3b5998;
				&:before {
					content: '\f09a';
				}
				&:hover {
					background-color: darken(#3b5998, 15%);
				}
			}
			&.linkedin {
				background-color: #0e76a8;
				&:before {
					content: '\f0e1';
				}
				&:hover {
					background-color: darken(#0e76a8, 15%);
				}
			}
			&.youtube {
				background-color: #c4302b;
				&:before {
					content: '\f16a';
				}
				&:hover {
					background-color: darken(#c4302b, 15%);
				}
			}
			&.pinterest {
				background-color: #bd081c;
				&:before {
					content: '\f0d2';
				}
				&:hover {
					background-color: darken(#bd081c, 15%);
				}
			}
			& + .social {
				margin-left: 15px;
			}
		}
	}
	.copyright {
		padding-top: 40px;
		padding-bottom: 40px;
		background-color: #ababab;
		color: #fff;
		font-size: 0.81rem;
		line-height: 1.54em;
		a {
			color: #fff;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}