// Header
#header {
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    @media (max-width: @screen-sm-max) {
        border-bottom: 1px solid #e5e5e5;
    }
    .logo {
        position: relative;
        z-index: 5;
        float: left;
        padding: 25px 35px;
        width: 206.5px;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        @media (max-width: @screen-sm-max) {
            padding: 7px 8px;
            width: auto;
            img {
                height: 46px;
                width: auto;
            }
        }
    }
    .logoWrapper {
        margin: 0 auto;
        max-width: 1440px;
    }
    .top {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        @media (max-width: @screen-sm-max) {
            position: relative;
        }
        .wrapper {
            margin: 0 auto;
            max-width: 1440px;
            .topMenu {
                float: right;
                padding: 35px;
                @media (min-width: @screen-md-min) and (max-width: 1349px) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                @media (max-width: @screen-sm-max) {
                    float: none;
                    padding: 0;
                }
            }
        }
    }
    .main {
        clear: both;
        border-top: 1px solid #d4d4d4;
        border-bottom: 1px solid #d4d4d4;
        @media (max-width: @screen-sm-max) {
            border: 0;
        }
    }
    .mainNav {
        margin: 0 auto;
        max-width: 1300px;
        > ul {
            float: none;
            margin: 0;
            padding: 0;
            list-style: none;
            > li {
                float: left;
                margin: 0;
                width: 20%;
                @media (max-width: @screen-sm-max) {
                    width: 100%;
                }
                > a, > .nolink {
                    display: block;
                    position: relative;
                    margin: 0;
                    padding: 34px 30px;
                    padding-left: 90px;
                    background-position: 20px center;
                    background-repeat: no-repeat;
                    background-size: 45px auto;
                    background-color: transparent;
                    color: #616161;
                    font-size: 1.13rem;
                    font-weight: 500;
                    line-height: 1.11em;
                    .transition(all .2s);
                    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                        padding-right: 10px;
                        padding-left: 65px;
                        background-position: 10px center;
                    }
                    &:before {
                        content: '';
                        display: none;
                        position: absolute;
                        bottom: -1px;
                        left: 30px;
                        height: 0;
                        width: 0;
                        border-left: 13px solid transparent;
                        border-right: 13px solid transparent;
                        border-bottom: 15px solid #fff;
                    }
                    .caret {
                        display: none;
                    }
                    @media (max-width: @screen-sm-max) {
                        padding-top: 30px;
                        padding-bottom: 30px;
                        border-bottom: 1px solid #e5e5e5;
                        .caret {
                            display: block;
                            margin: 0;
                            height: auto;
                            width: auto;
                            border: 0;
                            &:before {
                                content: '\f105';
                                display: block;
                                position: absolute;
                                top: 50%;
                                right: 30px;
                                margin-top: -11px;
                                color: #cfcfcf;
                                font-family: 'FontAwesome';
                                font-size: 1.44rem;
                                font-weight: 400;
                                line-height: 1em;
                            }
                        }
                    }
                }
                & + li {
                    border-left: 1px solid #d4d4d4;
                    @media (max-width: @screen-sm-max) {
                        border-left: 0;
                    }
                }
                &.sdb {
                    > a, > .nolink {
                        background-image: url(../img/picto-sdb.png);
                    }
                    &:hover, &.open, &.active, &.active-trail {
                        > a, > .nolink {
                            background-image: url(../img/picto-sdb--hover.png);
                        }
                    }
                }
                &.chauffage {
                    > a, > .nolink {
                        background-image: url(../img/picto-chauffage.png);
                    }
                    &:hover, &.open, &.active, &.active-trail {
                        > a, > .nolink {
                            background-image: url(../img/picto-chauffage--hover.png);
                        }
                    }
                }
                &.cuisine {
                    > a, > .nolink {
                        background-image: url(../img/picto-cuisine.png);
                    }
                    &:hover, &.open, &.active, &.active-trail {
                        > a, > .nolink {
                            background-image: url(../img/picto-cuisine--hover.png);
                        }
                    }
                }
                &.eau {
                    > a, > .nolink {
                        background-image: url(../img/picto-eau.png);
                    }
                    &:hover, &.open, &.active, &.active-trail {
                        > a, > .nolink {
                            background-image: url(../img/picto-eau--hover.png);
                        }
                    }
                }
                &.rangement {
                    > a, > .nolink {
                        background-image: url(../img/picto-rangement.png);
                    }
                    &:hover, &.open, &.active, &.active-trail {
                        > a, > .nolink {
                            background-image: url(../img/picto-rangement--hover.png);
                        }
                    }
                }
                &:hover, &.open, &.active, &.active-trail {
                    > a, > .nolink {
                        background-color: @brand-primary;
                        color: #fff;
                        font-size: 1rem;
                        line-height: 1.25em;
                    }
                }
                &.open {
                    > a, > .nolink {
                        &:before {
                            display: block;
                        }
                    }
                }
                // Niv 2
                > ul.dropdown-menu, .links {
                    position: absolute;
                    z-index: 1000;
                    top: 100%;
                    left: 0;
                    float: none;
                    margin: 1px 0 0;
                    padding: 35px 30px;
                    min-width: 300px;
                    background-color: #fff;
                    border: 0;
                    border-radius: 0;
                    list-style: none;
                    font-size: 1rem;
                    text-align: left;
                    -webkit-box-shadow: 0px 34px 49px 0px rgba(0, 0, 0, 0.22);
                    -moz-box-shadow: 0px 34px 49px 0px rgba(0, 0, 0, 0.22);
                    box-shadow: 0px 34px 49px 0px rgba(0, 0, 0, 0.22);
                    @media (max-width: @screen-sm-max) {
                        position: relative;
                        padding: 25px 20px;
                        border-bottom: 1px solid #e5e5e5;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }
                    > li {
                        margin: 0;
                        > a, > .nolink {
                            display: block;
                            margin: 0;
                            padding: 0 20px;
                            background-color: transparent;
                            color: #3c3c3c;
                            font-size: 1.13rem;
                            font-weight: 700;
                            line-height: 1.67em;
                            text-decoration: none;
                            .transition(all .2s);
                            .caret {
                                display: none;
                            }
                            @media (max-width: @screen-sm-max) {
                                padding-left: 0;
                                padding-right: 0;
                            }  
                        }
                        &:hover {
                            > a {
                                color: @brand-primary;
                            }
                        }
                        &.active, &.active-trail {
                            > a, > .nolink {
                                color: @brand-primary;
                            }
                        }
                        // Niv 3
                        > ul.dropdown-menu {
                            display: block;
                            position: relative;
                            z-index: 1000;
                            top: auto;
                            left: auto;
                            float: none;
                            margin: 10px 0 20px;
                            padding: 0;
                            min-width: 0;
                            background-color: transparent;
                            border: 0;
                            border-radius: 0;
                            font-size: 1rem;
                            list-style: none;
                            text-align: left;
                            -webkit-box-shadow: none;
                            -moz-box-shadow: none;
                            box-shadow: none;
                            > li {
                                margin: 0;
                                > a, > .nolink {
                                    display: block;
                                    position: relative;
                                    margin: 0;
                                    padding: 0 30px;
                                    background-color: transparent;
                                    color: #666666;
                                    font-size: 1rem;
                                    font-weight: 400;
                                    line-height: normal;
                                    white-space: normal;
                                    .transition(all .2s);
                                    &:before {
                                        content: '\f105';
                                        position: absolute;
                                        color: #b2b2b2;
                                        top: 2px;
                                        left: 20px;
                                        font-family: 'FontAwesome';
                                        font-size: 1em;
                                    }
                                    .caret {
                                        display: none;
                                    }
                                    @media (max-width: @screen-sm-max) {
                                        padding-left: 10px;
                                        padding-right: 10px;
                                        &:before {
                                            left: 0;
                                        }
                                    }  
                                }
                                & + li {
                                    margin-top: 10px;
                                }
                                &:hover, &.active, &.active-trail {
                                    > a, > .nolink {
                                        color: @brand-primary;
                                    }
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    > ul.dropdown-menu {
                        left: auto;
                        right: 0;
                    }
                }
                // Block
                .menu-attach-block-wrapper.orientation-horizontal .block {
                    position: relative;
                    top: auto;
                    margin-top: 25px;
                    padding: 0;
                    width: auto;
                    background-color: transparent;
                    .btn {
                        position: relative;
                        padding: 10px 15px;
                        padding-right: 30px;
                        width: 100%;
                        font-weight: 700;
                        text-align: left;
                        text-transform: uppercase;
                        &:after {
                            content:'\f105';
                            position: absolute;
                            top: 50%;
                            right: 15px;
                            margin-top: -15px;
                            font-family: 'FontAwesome';
                            font-size: 1.88rem;
                            font-weight: 400;
                            line-height: 1em;
                        }
                    }
                }
                // Megamenu
                &.mm {
                    position: static;
                    @media (max-width: @screen-sm-max) {
                        position: relative;
                    }
                    > ul.dropdown-menu {
                        display: none;
                        margin: 0;
                        padding-top: 55px;
                        width: 100%;
                        @media (max-width: @screen-sm-max) {
                            padding-top: 25px;
                        }
                        > li.grid {
                            .clearfix;
                            margin: 0 auto;
                            max-width: 1170px;
                            .linksWrapper {
                                float: left;
                                padding-right: 15px;
                                width: 35%;
                                @media (max-width: @screen-sm-max) {
                                    padding-bottom: 15px;
                                    padding-right: 0;
                                    width: 100%;
                                }
                            }
                            .blockWrapper {
                                float: left;
                                padding-left: 15px;
                                width: 65%;
                                @media (max-width: @screen-sm-max) {
                                    padding-top: 15px;
                                    padding-left: 0;
                                    width: 100%;
                                }
                                .menu-attach-block-wrapper.orientation-horizontal .block {
                                    margin: 0;
                                    .btn {
                                        padding-right: 20%;
                                    }
                                    p {
                                        margin: 0 0 20px;
                                        color:#2e3032;
                                        font-size: 1.13rem;
                                        font-weight: 500;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                    .col-md-6:first-child {
                                        padding-right: 40px;
                                        @media (max-width: @screen-sm-max) {
                                            padding-right: 15px;
                                            padding-bottom: 40px;
                                        }
                                    }
                                }
                                img {
                                    .img-responsive;
                                    height: auto !important;
                                }
                            }
                            .links {
                                position: relative;
                                top: auto;
                                left: auto;
                                margin: 0;
                                padding: 0;
                                min-width: 0;
                                background-color: transparent;
                                -webkit-box-shadow: none;
                                -moz-box-shadow: none;
                                box-shadow: none;
                                @media (max-width: @screen-sm-max) {
                                    border-bottom: 0;
                                }
                                > li {
                                    > a, > .nolink {
                                        padding: 0;
                                    }
                                    > .dropdown-menu {
                                        margin-bottom: 10px;
                                        @media (min-width: @screen-sm-min) {
                                            -webkit-column-count: 2;
                                            -moz-column-count: 2;
                                            column-count: 2;
                                        }
                                        > li {
                                            display: inline-block;
                                            margin: 0 0 10px;
                                            width: 100%;
                                            > a, > .nolink {
                                                padding: 0;
                                                padding-left: 10px;
                                                &:before {
                                                    left: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.open {
                        > ul.dropdown-menu {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .topNav {
        float: left;
        margin: 15px 0;
        @media (min-width: @screen-md-min) and (max-width: 1349px) {
            float: right;
        }
        @media (max-width: @screen-sm-max) {
            float: none;
            margin: 20px 0;
        }
        > ul {
            float: none;
            margin: 0;
            padding: 0;
            list-style: none;
            > li {
                float: left;
                margin: 0;
                @media (max-width: @screen-sm-max) {
                    float: none;
                }
                > a, > .nolink {
                    display: block;
                    margin: 0;
                    padding: 0;
                    background-color: transparent;
                    color: #737373;
                    font-size: 1rem;
                    line-height: 1.24em;
                    .transition(all .2s);
                    .caret {
                        display: none;
                    }
                    @media (max-width: @screen-sm-max) {
                        padding: 10px 20px;
                    }
                }
                & + li {
                    margin-left: 30px;
                    @media (max-width: @screen-sm-max) {
                        margin-left: 0;
                    }
                }
                &.gmap {
                    > a, > .nolink {
                        &:before {
                            content: '\f041';
                            display: inline-block;
                            margin-right: 5px;
                            font-family: 'FontAwesome';
                        }
                    }
                }
                &:hover, &.open, &.active, &.active-trail {
                    > a, > .nolink {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .search {
        float: left;
        margin: 15px 0;
        margin-left: 40px;
        text-decoration: none;
        @media (min-width: @screen-md-min) and (max-width: 1349px) {
            clear: both;
        }
        .fa {
            display: block;
            font-size: 1.13rem;
            line-height: 1.11em;
        }
    }
    .language {
        display: none;
        position: relative;
        float: left;
        margin: 15px 0;
        margin-left: 40px;
        @media (max-width: @screen-sm-max) {
            float: none;
            margin: 0;
            padding: 16px 20px;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
        }
        .label {
            display: block;
            color: @brand-primary;
            font-size: 0.88rem;
            font-weight: 700;
            line-height: 1.43em;
            text-transform: uppercase;
            cursor: pointer;
            .transition(all .2s);
            .fa {
                margin-left: 5px;
            }
            &:hover {
                color: darken(@brand-primary, 15%);
            }
        }
        ul {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            margin: 0;
            padding: 0;
            list-style: none;
            @media (max-width: @screen-sm-max) {
                top: auto;
                left: auto;
            }
            li {
                a {
                    display: block;
                    font-size: 0.88rem;
                    font-weight: 700;
                    line-height: 1.43em;
                    text-decoration: none;
                    text-transform: uppercase;
                }
            }
            &.expand {
                display: block;
            }
        }
    }
    .header-btn {
        display: block;
        float: left;
        @media (max-width: @screen-sm-max) {
            float: none;
        }
        &.first {
            margin-left: 40px;
            @media (max-width: @screen-sm-max) {
                margin: 30px 20px 0;
            }
        }
        &.last {
            margin-left: 15px;
            @media (max-width: @screen-sm-max) {
                margin: 15px 20px 30px;
            }
        }
    }

    @media (min-width: @screen-md-min) {
        &.headroom {
            will-change: transform;
            .transition(transform .2s linear);
        }
    
        &.headroom--not-top {
            border-bottom: 1px solid #d4d4d4;
            box-shadow: 0px 4px 6px 0px rgba(17, 16, 10, 0.2);
            .logo {
                padding: 15px 50px 14px 70px;
                border-right: 1px solid #d4d4d4;
                @media (min-width: @screen-lg-min) and (max-width: 1349px) {
                    padding-left: 40px;
                    padding-right: 50px;
                }
                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    padding: 27px 7px 26px;
                    width: auto;
                    .logoLarge {
                        display: none !important;
                    }
                    .logoMobile {
                        display: block !important;
                        height: 46px;
                        width: auto;
                    }
                }
            }
            .top {
                .wrapper {
                    .topMenu {
                        padding: 8px 25px;
                        @media (min-width: @screen-md-min) and (max-width: 1349px) {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }
            }
            .main {
                clear: none;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;  
                margin-top: 50px;
                border-bottom: 0;
            }
            .mainNav {
                margin: 0;
                width: 100%;
                max-width: 1035px;
                > ul {
                    > li {
                        > a, > .nolink {
                            padding: 14px 30px 14px 70px;
                            background-position: 15px center;
                            background-size: 35px auto;
                            font-size: 1rem;
                            line-height: 1.25em;
                            @media (min-width: @screen-lg-min) and (max-width: 1349px) {
                                padding-left: 65px;
                                padding-right: 15px;
                            }
                            @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                                padding-left: 55px;
                                padding-right: 10px;
                                background-position: 10px center;
                            }
                            &:before {
                                left: 45px;
                            }
                        }
                        > ul.dropdown-menu {
                            margin-top: 1px;
                        }
                    }
                }
            }
            .topNav, .search, .language {
                margin-top: 7px;
                margin-bottom: 7px;
            }
            .topNav {
                @media (min-width: @screen-md-min) and (max-width: 1349px) {
                    float: left;
                }
                > ul {
                    >li {
                        & + li {
                            @media (min-width: @screen-md-min) and (max-width: 1349px) {
                                margin-left: 15px;
                            }
                        }
                    }
                }
            }
            .search {
                @media (min-width: @screen-md-min) and (max-width: 1349px) {
                    clear: none;
                }
            }
            .search, .language {
                @media (min-width: @screen-md-min) and (max-width: 1349px) {
                    margin-left: 15px;
                }
            }
            .header-btn {
                padding: 5px 20px;
                &.first {
                    margin-left: 35px;
                    @media (min-width: @screen-md-min) and (max-width: 1349px) {
                        margin-left: 12px;
                    }
                }
                &.last {
                    margin-left: 2px;
                }
            }
        }

        &.headroom--pinned {
            -webkit-transform: translateY(0%);
            -moz-transform: translateY(0%);
            -ms-transform: translateY(0%);
            -o-transform: translateY(0%);
            transform: translateY(0%);
        }
        &.headroom--unpinned {
            box-shadow: none;
            -webkit-transform: translateY(-100%);
            -moz-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            -o-transform: translateY(-100%);
            transform: translateY(-100%);
        }
    }
}

#searchBox {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: @brand-primary;
    box-shadow: 0px 4px 6px 0px rgba(17, 16, 10, 0.2);
    #block-search-form {
        padding: 50px 15px;
    }
    .form-group {
        margin: 0;
    }
    form {
        margin: 0 auto;
        max-width: 970px;
    }
    .form-control {
        float: left;
        padding: 5px 15px;
        height: 50px;
        width: calc(~"100% - 50px");
        background-color: #fff;
        border: 1px solid #a7a7a7;
        border-radius: 5px 0 0 5px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    .form-control, output {
        display: block;
        color: #000;
        font-size: 1rem;
        font-weight: 400;
        line-height: normal;
    }
    ::-webkit-input-placeholder {
      color: #000;
    }
    ::-moz-placeholder {
      color: #000;
    }
    :-ms-input-placeholder {
      color: #000;
    }
    :-moz-placeholder {
      color: #000;
    }
    .input-group-btn {
        float: left;
        display: block;
        .btn {
            display: block;
            padding: 5px;
            height: 50px;
            width: 50px;
            background-color: fade(#292624, 30%);
            border: 0;
            border-radius: 0 5px 5px 0;
            color: #fff;
            font-size: 1.13rem;
            font-weight: 400;
            line-height: 1em;
            &:hover {
                background-color: fade(#292624, 60%);
            }
        }
    }
    .input-group-addon {
        display: none;
    }
}

.navMobile {
    .clearfix;
    float: right;
    > a {
        display: block;
        float: left;
        height: 60px;
        width: 60px;
        background-color: transparent;
        border-left: 1px solid #e5e5e5;
        color: @brand-primary;
        font-size: 1.44rem;
        font-weight: 400;
        line-height: 60px;
        text-align: center;
        .transition(all .2s);
        &.search-mobile {
            font-size: 1.13rem;
        }
        &.trigger-menu {
            font-size: 1.25rem;
        }
        &:hover {
            background-color: @brand-primary;
            color: #fff;
        }
        &.pro {
            background-image: url(../images/plumber.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 32px auto;
            &:hover {
                background-image: url(../images/plumber-white.svg);
            }
        }
    }
}

@media (max-width: @screen-sm-max) {
    nav#nav {
        position: fixed;
        top: 60px;
        right: 0;
        bottom: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        background-color: #fff;
        border-top: 1px solid #e5e5e5;
        overflow-x: auto;
        overflow-y: scroll;
        .transition(all .35s);
    }
}

body.open-menu {
    @media (max-width: @screen-sm-max) {
        height: 100%;
        overflow: hidden;
        nav#nav {
            visibility: visible;
            opacity: 1;
        }
    }
}


#banner {
    position: relative;
    .titleAbsolute {
        position: absolute;
        z-index: 120;
        bottom: 0;
        left: 0;
        width: 100%;
        .wrap {
            position: relative;
            padding-top: 45px;
            padding-right: 30px;
            width: 66.66666667%;
            background-color: #fff;
            @media (max-width: @screen-xs-max) {
                width: 100%;
            }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 100%;
                bottom: 0;
                width: 9999px;
                background-color: #fff;
            }
        }
    }
    &.titleOnly {
        background-color: #000;
        height: 550px;
        &:before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 70%;
            right: 0;
            bottom: 0;
            left: 0;
            background: -moz-linear-gradient(top,  rgba(17,16,10,0) 0%, rgba(17,16,10,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(17,16,10,0) 0%,rgba(17,16,10,1) 100%);
            background: linear-gradient(to bottom,  rgba(17,16,10,0) 0%,rgba(17,16,10,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0011100a', endColorstr='#11100a',GradientType=0 );
            opacity: .7;
        }
        .title {
            position: absolute;
            z-index: 10;
            bottom: 60px;
            left: 0;
            width: 100%;
            h1 {
                margin: 0;
                color: #fff;
                line-height: 1em;
            }
        }
    }
    &.fullTitle {
        height: 425px;
    }
    &.mutlipleBanner {
        .titleAbsolute {
            .container {
                position: relative;
            }
            .wrap {
                width: 58.33333333%;
                @media (max-width: @screen-md-max) {
                    width: 50%;
                }
                @media (max-width: @screen-sm-max) {
                    width: 66.66666667%;
                }
                @media (max-width: @screen-xs-max) {
                    width: 100%;
                }
            }
        }
        .imgTexte {
            position: absolute;
            z-index: 110;
            top: 80px;
            left: 0;
            width: 100%;
        }
        .cycle-slideshow {
            height: 600px;
            > .slide {
                height: 600px;
                width: 100%;
                background-color: #000;
                .bg {
                    opacity: .7;
                }
            }
        }
    }
}