@-webkit-keyframes hingeRight {
	0% {
		-webkit-transform:rotate(0);
		-webkit-transform-origin:top right;
		-webkit-animation-timing-function:ease-in-out;
	}
	
	20%,60% {
		-webkit-transform:rotate(-80deg);
		-webkit-transform-origin:top right;
		-webkit-animation-timing-function:ease-in-out;
	}
	
	40% {
		-webkit-transform:rotate(-60deg);
		-webkit-transform-origin:top right;
		-webkit-animation-timing-function:ease-in-out;
	}
	
	80% {
		-webkit-transform:rotate(-60deg) translateY(0);
		opacity:1;
		-webkit-transform-origin:top right;
		-webkit-animation-timing-function:ease-in-out;
	}
	
	100% {
		-webkit-transform:translateY(700px);
		opacity:0;
	}
}

@-moz-keyframes hingeRight {
	0% {
		-moz-transform:rotate(0);
		-moz-transform-origin:top right;
		-moz-animation-timing-function:ease-in-out;
	}
	
	20%,60% {
		-moz-transform:rotate(-80deg);
		-moz-transform-origin:top right;
		-moz-animation-timing-function:ease-in-out;
	}
	
	40% {
		-moz-transform:rotate(-60deg);
		-moz-transform-origin:top right;
		-moz-animation-timing-function:ease-in-out;
	}
	
	80% {
		-moz-transform:rotate(-60deg) translateY(0);
		opacity:1;
		-moz-transform-origin:top right;
		-moz-animation-timing-function:ease-in-out;
	}
	
	100% {
		-moz-transform:translateY(700px);
		opacity:0;
	}
}

@-ms-keyframes hingeRight {
	0% {
		-ms-transform:rotate(0);
		-ms-transform-origin:top right;
		-ms-animation-timing-function:ease-in-out;
	}
	
	20%,60% {
		-ms-transform:rotate(-80deg);
		-ms-transform-origin:top right;
		-ms-animation-timing-function:ease-in-out;
	}
	
	40% {
		-ms-transform:rotate(-60deg);
		-ms-transform-origin:top right;
		-ms-animation-timing-function:ease-in-out;
	}
	
	80% {
		-ms-transform:rotate(-60deg) translateY(0);
		opacity:1;
		-ms-transform-origin:top right;
		-ms-animation-timing-function:ease-in-out;
	}
	
	100% {
		-ms-transform:translateY(700px);
		opacity:0;
	}
}

@-o-keyframes hingeRight {
	0% {
		-o-transform:rotate(0);
		-o-transform-origin:top right;
		-o-animation-timing-function:ease-in-out;
	}
	
	20%,60% {
		-o-transform:rotate(-80deg);
		-o-transform-origin:top right;
		-o-animation-timing-function:ease-in-out;
	}
	
	40% {
		-o-transform:rotate(-60deg);
		-o-transform-origin:top right;
		-o-animation-timing-function:ease-in-out;
	}
	
	80% {
		-o-transform:rotate(-60deg) translateY(0);
		opacity:1;
		-o-transform-origin:top right;
		-o-animation-timing-function:ease-in-out;
	}
	
	100% {
		-o-transform:translateY(700px);
		opacity:0;
	}
}

@keyframes hingeRight {
	0% {
		transform:rotate(0);
		transform-origin:top right;
		animation-timing-function:ease-in-out;
	}
	
	20%,60% {
		transform:rotate(-80deg);
		transform-origin:top right;
		animation-timing-function:ease-in-out;
	}
	
	40% {
		transform:rotate(-60deg);
		transform-origin:top right;
		animation-timing-function:ease-in-out;
	}
	
	80% {
		transform:rotate(-60deg) translateY(0);
		opacity:1;
		transform-origin:top right;
		animation-timing-function:ease-in-out;
	}
	
	100% {
		transform:translateY(700px);
		opacity:0;
	}
}

.hingeRight {
	.animation-name(hingeRight);
}

.animated.hingeRight {
}