body:not(.cke_editable) {
	padding-top: 210px;
	@media (max-width: @screen-sm-max) {
		padding-top: 60px;
	}
	#main {
		padding-bottom: 150px;
	}
	&.admin-menu.adminimal-menu {
		#header {
			top: 29px;
		}
	}
}

.container {
	&.md-width {
		max-width: 970px;
	}
}

.bg {
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.posCenterHor {
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translate(-50%,0);
	-moz-transform: translate(-50%,0);
	-ms-transform: translate(-50%,0);
	-o-transform: translate(-50%,0);
	transform: translate(-50%,0);
}
.posCenterCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
}

.same-height--md {
	@media (min-width: @screen-md-min) {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
}
.same-height--sm {
	@media (min-width: @screen-sm-min) {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
}

.displayInlBlc {
	display: inline-block;
	vertical-align: middle;
	float: none;
}

.fontResize {
	font-size: 0;
}

.lineTitle {
	margin-top: 40px;
	margin-bottom: 50px;
	text-align: center;
	overflow: hidden;
	h2 {
		display: inline-block;
		position: relative;
		margin: 0;
		padding: 0 30px;
		color: @brand-primary;
		font-weight: 700;
		&:after, &:before {
			content: '';
			position: absolute;
			top: 50%;
			height: 1px;
			width: 9999px;
			background-color: #e5e5e5;
		}
		&:before {
			right: 100%;
		}
		&:after {
			left: 100%;
		}
	}
}

.shadow {
	box-shadow: 0px 41px 49px 0px rgba(0, 0, 0, 0.21);
}

// Types
h1 {
	margin-top: 0;
	margin-bottom: 15px;
	color: #2a2a2a;
	font-weight: 700;
	line-height: normal;
}
h2 {
	margin-top: 30px;
	margin-bottom: 30px;
}
h3 {
	margin-top: 30px;
	margin-bottom: 30px;
	color: @brand-primary;
	line-height: 1.08em;
}
p, ul, ol {
	margin-bottom: 25px;
	&:last-child {
		margin-bottom: 0;
	}
}
ul,ol {
	li:not(:last-child) {
		margin-bottom: 5px;
	}
}

// custom ul
.customList {
	padding: 0;
	list-style: none;
	li {
		position: relative;
		padding-left: 15px;
		&:before {
			content: '\f0da';
			position: absolute;
			color: @brand-primary;
			top: 0;
			left: 0;
			font-family: 'FontAwesome';
			font-size: 1em;
		}
		ul {
			li {
				&:before {
					content: '\f105';
				}
			}
		}
	}
}
.customListCheck {
	.customList;
	margin: 0;
	li {
		margin: 0;
		padding-left: 20px;
		color: @brand-primary;
		font-size: 1rem;
		line-height: 1.56em;
		&:before {
			content: '\f00c';
		}
		ul {
			li {
				&:before {
					content: '\f00c';
				}
			}
		}
	}
}

// Breadcrumb
.breadcrumb {
	margin: 0;
	padding: 0;
	list-style: none;
	background-color: transparent;
	border-radius: 0;
	font-size: 0;
	li {
		display: inline-block;
		margin: 0;
		color: #000;
		font-size: 0.94rem;
		line-height: 1em;
		a {
			color: #000;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
		&.active {
			color: #8f8f8f;
		}
		& + li {
			&:before {
				content: "/\00a0";
				margin-left: 5px;
				padding: 0;
				color: #000;
			}
		}
	}
}

// Lead
p.lead {
	margin-bottom: @line-height-computed;
	color: #b7b7b7;
	font-size: 1.5rem;
	font-weight: 300;
	line-height: normal;
}

// Evidence
p.evidence, div.evidence {
	color: #fff;
	background-color: @brand-primary;
	padding: @line-height-computed*1.5 @line-height-computed*2;
	margin-bottom: @line-height-computed;
	h2 {
		margin: 0;
	}
	@media (max-width: @screen-sm-max) {
		padding: 30px;
	}
	@media (max-width: @screen-xs-max) {
		padding: 15px;
	}

	a {
		color: #fff;
	}

	a.btn {
		background-color: #fff;
		color: @brand-primary;

		&:hover {
			background-color: darken(#fff, 10%);
		}
	}
}

// Img
img {
	max-width: 100%;
	height: auto;

	&[style*="left"] {
		margin-right: @line-height-computed;
		margin-bottom: @line-height-computed;
	}

	&[style*="right"] {
		margin-left: @line-height-computed;
		margin-bottom: @line-height-computed;
	}
}

// Table
table {
	border: 1px solid #d8d8d8;
	margin-bottom: @line-height-computed;
	width: 100%;

	th {
		background-color: @brand-primary;
		color: #fff;
		text-transform: uppercase;
		font-weight: 400;
	}

	td {
		background-color: #f0f0f0;
	}

	tr.even td {
		background-color: darken(#f0f0f0, 5%);
	}

	td, th {
		padding: @line-height-computed/2 @line-height-computed;
	}
}
@media (max-width: @screen-xs-max) {
	.table-responsive {
		td {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

// Iframe responsive
.iframe-embed {
	display: block;
	position: relative;
	margin-bottom: @line-height-computed;
	padding: 0;
	padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

	iframe {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}

// Boutons
.btn {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	padding: 13px 23px;
	border: 2px solid transparent;
	border-radius: 40px;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.25em;
	text-align: center;
	outline: 0;
	white-space: normal;
	.transition(all .2s);
	&.btn-primary, &.btn-default, &.btn1 {
		background-color: @brand-primary;
		border-color: @brand-primary;
		color: #fff;
		&:hover {
			background-color: darken(@brand-primary, 15%);
			border-color: darken(@brand-primary, 15%);
		}
	}
	&.btn-white {
		background-color: #fff;
		border-color: #fff;
		color: @brand-primary;
		font-weight: 700;
		&:hover {
			background-color: @brand-primary;
			border-color: @brand-primary;
			color: #fff;
		}
	}
	&.btn-anthracite {
		background-color: #252525;
		border-color: #252525;
		color: #fff;
		&:hover {
			background-color: darken(#252525, 15%);
			border-color: darken(#252525, 15%);
		}
	}
	&.btn-border--primary, &.btn-secondary, &.btn2 {
		background-color: transparent;
		border-color: @brand-primary;
		color: @brand-primary;
		&:hover {
			background-color: @brand-primary;
			border-color: @brand-primary;
			color: #fff;
		}
	}
	&.btn-border--black {
		padding-left: 30px;
		padding-right: 30px;
		background-color: transparent;
		border-color: #252525;
		color: #252525;
		font-size: 1.13rem;
		line-height: 1.11em;
		&:hover {
			background-color: #252525;
			color: #fff;
		}
	}
	&:focus {
		outline: 0;
	}
}

.moreBtn {
	display: inline-block;
	height: 48px;
	width: 48px;
	background-color: @brand-primary;
	border-radius: 50%;
	color: #fff;
	font-size: 1.56rem;
	font-weight: 400;
	line-height: 48px;
	text-align: center;
	text-decoration: none;
	.transition(all .2s);
	&:focus {
		text-decoration: none;
		color: #fff;
	}
	&:hover {
		background-color: #252525;
		color: #fff;
		text-decoration: none;
	}
	&.moreBtnWhite {
		background-color: #fff;
		color: @brand-primary;
		&:hover {
			background-color: @brand-primary;
			color: #fff;
		}
	}
}


// Item
.itemOverlay {
	display: none;
}

.item {
	display: block;
	position: relative;
	margin: 15px 0;
	text-decoration: none !important;
	&--contentFloat {
		.content {
			position: absolute;
			padding: 45px 35px;
			min-height: 310px;
			width: 50%;
			background-color: #fff;
			@media (max-width: @screen-md-max) {
				min-height: 180px;
			}
			@media (max-width: @screen-sm-max) {
				position: relative;
				min-height: 150px;
				width: 100%;
				box-shadow: none;
			}
			@media (max-width: @screen-xs-max) {
				min-height: 0;
			}
			h3 {
				margin: 0 0 25px;
				color: #252525;
				font-size: 1.31rem;
				line-height: 1.43em;
				@media (max-width: @screen-md-max) {
					margin: 0;
				}
			}
			p {
				margin: 0;
				color: #9b9a99;
				font-size: 1rem;
				line-height: 1.5em;
			}
			.moreBtn {
				position: absolute;
				bottom: -24px;
				left: 35px;
			}
		}
		&.content_topLeft {
			.content {
				top: 20px;
				left: 0;
				@media (max-width: @screen-md-max) {
					p {
						display: none;
					}
				}
				@media (max-width: @screen-sm-max) {
					top: auto;
					left: auto;
				}
			}
			.imgWrapper {
				padding-left: 15%;
				@media (max-width: @screen-sm-max) {
					padding-left: 0;
				}
			}
		}
		&.content_bottomRight {
			margin: 30px 0;
			.content {
				bottom: 0;
				right: 0;
				min-height: 250px;
				width: 40%;
				@media (max-width: @screen-sm-max) {
					min-height: 170px;
					width: 100%;
				}
			}
			.imgWrapper {
				padding-right: 15%;
				@media (max-width: @screen-sm-max) {
					padding-right: 0;
				}
			}
		}
	}
	&--cat {
		.content {
			position: relative;
			padding: 25px 15px;
			min-height: 215px;
			background-color: #fff;
			.transition(all .2s);
			@media (max-width: @screen-md-max) {
				min-height: 210px;
			}
			@media (max-width: @screen-xs-max) {
				min-height: 0;
			}
			h3 {
				margin: 0;
				color: #000;
				font-size: 1.13rem;
				font-weight: 700;
			}
			.cat {
				margin: 0;
				color: #adadad;
				font-size: 0.88rem;
				font-weight: 500;
				line-height: normal;
				text-transform: uppercase;
			}
			p:not(.cat) {
				margin: 10px 0 0;
				color: #4f4f4f;
				a {
					color: #131112;
					text-decoration: underline;
				}
			}
			.moreBtn {
				position: absolute;
				top: -24px;
				right: 30px;
			}
		}
		&.item--catHidden {
			.content {
				padding: 30px 15px;
				h3 {
					min-height: 60px;
					color: #252525;
					font-size: 1.5rem;
					font-weight: 500;
					line-height: 1.25em;
				}
				p {
					margin: 0;
					color: #9b9a99;
					font-size: 1rem;
					line-height: 1.5em;
				}
			}
		}
	}
	&--fullClick {
		.imgWrapper {
			overflow: hidden;
			img {
				.transition(all .2s);
			}
		}
		&:hover {
			.imgWrapper {
				img {
					-webkit-transform: scale(1.1);
					-moz-transform: scale(1.1);
					-ms-transform: scale(1.1);
					-o-transform: scale(1.1);
					transform: scale(1.1);
				}
			}
			.content {
				background-color: @brand-primary;
				h3, .cat, p {
					color: #fff;
					a {
						color: #fff;
					}
				}
			}
		}
	}
	&--large {
		margin: 0;
		&.item--contentFloat {
			.content {
				padding: 60px 35px;
				min-height: 0;
				width: 33.33333333%;
				@media (max-width: @screen-sm-max) {
					width: 100%;
				}
				@media (max-width: @screen-xs-max) {
					padding: 60px 15px;
				}
				&.shadow {
					box-shadow: 0px 41px 49px 0px rgba(0, 0, 0, 0.3);
				}
				p {
					margin: 0 0 25px;
					color: #3a3a3a;
					font-weight: 500;
				}
				.btn {
					font-size: 1.13rem;
					line-height: 1.11em;
				}
			}
			.imgWrapper {
				img {
					box-shadow: 0px 17px 70px 0px rgba(0, 0, 0, 0.19);
				}
			}
			&.content_topLeft {
				.content {
					top: 15px;
					@media (max-width: @screen-md-max) {
						p {
							display: block;
						}
					}
				}
				.imgWrapper {
					padding: 0;
					text-align: right;
					img {
						display: inline-block;
					}
				}
			}

		}
	}
	&--price {
		margin: 20px 0;
		background-color: #fff;
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.14);
		.imgWrapper {
			position: relative;
			padding: 15px;
			height: 290px;
			border-bottom: 1px solid #e5e5e5;
			.wrap {
				position: relative;
				height: 100%;
				overflow: hidden;
			}
			img {
				height: auto;
				max-height: 100%;
				width: auto;
				max-width: 100%;
				&.img-alt {
					width: 200px;
				}
			}
		}
		.content {
			.clearfix;
			position: relative;
			.magasins {
				position: absolute;
				bottom: 100%;
				left: 15px;
				margin: 0;
				padding: 2px 8px;
				max-width: calc(~"100% - 30px");
				background-color: @brand-primary;
				color: #f1f1f1;
				font-size: 1rem;
				line-height: 1.5em;
			}

		}
		.title {
			float: left;
			padding: 15px;
			min-height: 130px;
			width: 65%;
			border-right: 1px solid #e5e5e5;
			.transition(all .2s);
			@media (max-width: @screen-md-max) {
				min-height: 180px;
			}
			@media (max-width: @screen-sm-max) {
				min-height: 155px;
			}
			@media (max-width: @screen-xs-max) {
				min-height: 0;
				width: 100%;
				border-bottom: 1px solid #e5e5e5;
				border-right: 0;
			}
			h3 {
				margin: 0;
				min-height: 50px;
				color: #252525;
				font-size: 1.13rem;
				line-height: 1.39em;
				.transition(all .2s);
			}
			p {
				margin: 0;
				color: #9b9a99;
				font-size: 1rem;
				line-height: 1.5em;
				.transition(all .2s);
			}
		}
		.price {
			position: relative;
			float: left;
			padding: 25px 15px 15px;
			min-height: 90px;
			width: 35%;
			color: @brand-primary;
			font-size: 1.25rem;
			line-height: 1.3em;
			text-align: center;
			@media (max-width: @screen-xs-max) {
				width: 100%;
			}
			.wrap {
				.posCenterCenter;
				text-align: right;
				@media (max-width: @screen-xs-max) {
					text-align: center;
				}
			}
			.promo {
				color: #a3a3a3;
				text-decoration: line-through;
			}
			.multi {
				color: #878787;
				font-size: 0.88rem;
				font-weight: 700;
				line-height: 1em;
			}
		}
		&:hover {
			.title {
				background-color: @brand-primary;
				h3, p {
					color: #fff;
				}
			}
		}
		&.item--price2 {
			margin: 15px 0;
			.title {
				min-height: 110px;
				padding: 20px 30px;
				@media (max-width: @screen-md-max) {
					min-height: 130px;
				}
				@media (max-width: @screen-sm-max) {
					min-height: 110px;
				}
				@media (max-width: @screen-xs-max) {
					min-height: 0;
				}
				h3 {
					min-height: 0;
					color: @brand-primary;
					font-size: 1rem;
					font-weight: 700;
				}
				p {
					color: #434343;
				}
			}
			.price {
				padding: 20px 15px;
			}
			&:hover {
				.title {
					h3, p {
						color: #fff;
					}
				}
			}
		}
	}
	&--list {
		margin: 25px 0;
		.content {
			padding: 20px 0;
			h3 {
				margin: 0 0 10px;
				color: #000;
				font-size: 1.13rem;
				font-weight: 700;
				line-height: 1.44em;
			}
			ul {
				.customListCheck;
				min-height: 140px;
				@media (max-width: @screen-md-max) {
					min-height: 150px;
				}
				@media (max-width: @screen-xs-max) {
					min-height: 0;
				}
			}
			a {
				margin-top: 20px;
				padding-left: 40px;
				padding-right: 40px;
				font-weight: 400;
			}
		}
	}
	&--label {
		padding: 35px 50px;
		background-color: #f3f3f3;
		@media (max-width: @screen-md-max) {
			min-height: 150px;
		}
		h3 {
			margin: 0;
			color: #000;
			font-size: 1.13rem;
			font-weight: 700;
			line-height: 1.44em;
		}
		p {
			margin: 0;
			color: #a1a1a1;
			font-size: 1.13rem;
			line-height: 1.44em;
		}
	}
	&--simpleTextImage {
		.content {
			padding-top: 30px;
			padding-bottom: 30px;
			h3 {
				margin: 0 0 25px;
				color: @brand-primary;
				font-size: 1.56rem;
				line-height: normal;
			}
			p {
				color: #6b6b6a;
				font-size: 1rem;
				line-height: 1.38em;
			}
		}
		&.right {
			.imgWrapper {
				left: 50%;
				@media (max-width: @screen-xs-max) {
					left: auto;
				}
			}
			.content {
				right: 50%;
				@media (max-width: @screen-xs-max) {
					right: auto;
				}
			}
		}
	}
	&--video {
		margin: 0;
		background-color: #181215;
		&:before {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-image: url(../img/picto-play.png);
			background-position: center;
			background-repeat: no-repeat;
			background-size: 75px auto;
		}
		img {
			opacity: .8;
			.transition(all .2s);
		}
		&:hover {
			img {
				opacity: .2;
			}
		}
	}
	&--titleBottom {
		.imgWrapper {
			overflow: hidden;
			img {
				.transition(all .2s);
			}
		}
		.content {
			position: relative;
			min-height: 100px;
			background-color: #000;
			text-align: left;
		}
		h3 {
			position: absolute;
			top: 50%;
			left: 0;
			margin: 0;
			padding: 25px 30px;
			padding-right: 60px;
			width: 100%;
			color: #fff;
			font-size: 1.25rem;
			font-weight: 500;
			line-height: 1.25em;
			-webkit-transform: translate(0,-50%);
			-moz-transform: translate(0,-50%);
			-ms-transform: translate(0,-50%);
			-o-transform: translate(0,-50%);
			transform: translate(0,-50%);
		}
		.fa {
			position: absolute;
			top: 50%;
			right: 30px;
			margin-top: -15px;
			color: #fff;
			font-size: 1.88rem;
			font-weight: 400;
			line-height: 1em;
			.transition(all .2s);
		}
		&:hover {
			.imgWrapper {
				img {
					-webkit-transform: scale(1.1);
					-moz-transform: scale(1.1);
					-ms-transform: scale(1.1);
					-o-transform: scale(1.1);
					transform: scale(1.1);
				}
			}
			.fa {
				right: 45px;
			}
		}
	}
	&.conseil {
		min-height: 500px;
		@media (max-width: @screen-xs-max) {
			min-height: auto;
		}
	}
}

// overlay
.modal-dialog {
    margin: 60px auto;
    width: 970px;
    max-width: 100%;
    @media (max-width: @screen-xs-max) {
		margin: 0 auto;
	}
}
.modal-content {
    background-color: #fff;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 0px 41px 49px 0px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 41px 49px 0px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 41px 49px 0px rgba(0, 0, 0, 0.21);
    .container {
    	width: 100% !important;
    }
}
.modal-body {
    position: relative;
    padding: 60px;
	@media (max-width: @screen-xs-max) {
		padding: 30px 15px;
	}
    > .close {
    	position: absolute;
    	top: 15px;
    	right: 15px;
    	@media (max-width: @screen-xs-max) {
			top: 0;
		}
    }
}
.modalCustom {
	position: fixed;
	z-index: 1606;
	top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    .transition(opacity .35s linear);
    .modalWrapper {
    	position: relative;
    	z-index: 0;
    	// padding: 165px 0 130px;
    	padding: 60px 0 30px;
    	min-height: 100%;
		-webkit-transform: translate(0,-25%);
		-moz-transform: translate(0,-25%);
	    -ms-transform: translate(0,-25%);
	    -o-transform: translate(0,-25%);
	    transform: translate(0,-25%);
	    .transition(transform .35s ease-out);
	    @media (max-width: @screen-xs-max) {
			padding: 60px 0;
		}
    }
    .modalClose {
    	position: fixed;
    	z-index: 20;
    	top: 50px;
    	right: 75px;
    	@media (max-width: @screen-xs-max) {
			top: 15px;
    		right: 15px;
		}
    	a {
    		display: block;
    		color: #292b2a;
    		font-size: 1.56rem;
	    	font-weight: 400;
	    	line-height: 1em;
	    	.transition(all .2s);
	    	&:hover {
	    		color: @brand-primary;
	    	}
    	}
    }
    .modalNav {
    	a {
    		position: fixed;
    		z-index: 10;
    		top: 0;
    		bottom: 0;
    		width: 150px;
    		display: block;
    		background: transparent;
    		color: #292b2a;
    		font-size: 2.19rem;
    		font-weight: 400;
    		line-height: 1em;
    		.transition(all .35s);
    		@media (max-width: @screen-md-max) {
				width: 60px;
			}
			@media (max-width: @screen-sm-max) {
				width: 30px;
			}
    		.fa {
    			position: absolute;
    			z-index: 1;
    			top: 50%;
    			margin-top: -35px;
    		}
    		&:after {
    			content: '';
    			position: absolute;
    			z-index: 0;
    			top: 0;
    			bottom: 0;
    			width: 0;
    			.transition(all .35s);
    		}
    		&.prev {
    			left: 0;
    			.fa {
    				left: 75px;
    				@media (max-width: @screen-md-max) {
						left: 30px;
					}
					@media (max-width: @screen-sm-max) {
						left: 15px;
					}
    			}
    			&:after {
    				left: 0;
    				background: -moz-linear-gradient(left,  rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);
					background: -webkit-linear-gradient(left,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
					background: linear-gradient(to right,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00000000',GradientType=1 );
    			}
    		}
    		&.next {
    			right: 0;
    			.fa {
    				right: 75px;
    				@media (max-width: @screen-md-max) {
						right: 30px;
					}
					@media (max-width: @screen-sm-max) {
						right: 15px;
					}
    			}
    			&:after {
    				right: 0;
    				background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%);
					background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
					background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#33000000',GradientType=1 );
    			}
    		}
    		&:hover {
    			&:after {
    				width: 100%;
    			}
    		}
    	}
    }
    .modal {
    	background-color: rgba(0,0,0,.5);
    }
    &#inspirationOverlay {
    	@media (min-width: @screen-md-min) and (min-height: @screen-sm-min) {
	    	.modalWrapper, .modalContent, .item-inspi, .imgWrapper {
	    		height: 100%;
	    	}
	    	.imgWrapper {
	    		text-align: center;
	    		img {
					height: auto;
					max-height: 100%;
					width: auto;
					max-width: 100%;
	    		}
	    	}
    	}
    }
}
.modalBackground {
	position: fixed;
	z-index: 1605;
	top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	background-color: #dfdfdf;
	opacity: 0;
	visibility: hidden;
    .transition(opacity .35s linear);
}
body.modal-open {
	.modalWrapper {
		z-index: 30;
	}
}
body.overlayOn {
	overflow: hidden;
}

.modalCustom.open {
	overflow-x: hidden;
	overflow-y: auto;
	opacity: 1;
	visibility: visible;
    .modalWrapper {
    	-webkit-transform: translate(0,0);
    	-moz-transform: translate(0,0);
	    -ms-transform: translate(0,0);
	    -o-transform: translate(0,0);
    	transform: translate(0,0);
    }
    & + .modalBackground {
		opacity: 1;
		visibility: visible;
	}
}
.modalCustom.closeTransition {
	opacity: 0;
    .modalWrapper {
    	-webkit-transform: translate(0,-25%);
		-moz-transform: translate(0,-25%);
	    -ms-transform: translate(0,-25%);
	    -o-transform: translate(0,-25%);
	    transform: translate(0,-25%);
    }
    & + .modalBackground {
		opacity: 0;
	}
}

// Addthis
.addthis_toolbox {
	position: absolute;
	top: 0;
	right: 15px;
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		float: left;
		margin: 0;
		& + li {
			margin-left: 10px;
		}
		a {
			display: block;
			margin: 0;
			height: 36px;
			width: 36px;
			border-radius: 50%;
			color: #fff;
			font-size: 1.25rem;
			font-weight: 400;
			line-height: 36px;
			text-align: center;
			.transition(all .2s);
			span {
				display: none;
			}
		}
		&.twitter {
			a {
				background-color: #55acee;
				&:hover {
					background-color: darken(#55acee,15%)
				}
			}
		}
		&.facebook {
			a {
				background-color: #3b5998;
				&:hover {
					background-color: darken(#3b5998,15%)
				}
			}
		}
		&.google {
			a {
				background-color: #dd4b39;
				&:hover {
					background-color: darken(#dd4b39,15%)
				}
			}
		}
		&.linkedin {
			a {
				background-color: #007bb5;
				&:hover {
					background-color: darken(#007bb5,15%)
				}
			}
		}
		&.pinterest {
			a {
				background-color: #cb2027;
				&:hover {
					background-color: darken(#cb2027,15%)
				}
			}
		}
	}
}

// Tooltip
.tooltip {
	&.top .tooltip-arrow {
	    top: 100%;
	    left: 50% !important;
	    right: 0;
	    margin: -5px 0 0 -8px;
	    border-width: 16px 8px 0;
	    border-top-color: fade(#040404, 75%);
	}
	.tooltip-inner {
		padding: 30px;
		max-width: 290px;
		background-color: fade(#040404, 75%);
		border-radius: 0;
		color: #fff;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5em;
		text-align: left;
	}
}

// title
.viewTitle {
	padding-top: 65px;
	padding-bottom: 40px;
	.container {
		position: relative;
	}
	h1 {
		margin: 0 0 20px;
		max-width: calc(~"100% - 300px");
		font-size: 3.25rem;
		line-height: 1em;
		@media (max-width: @screen-xs-max) {
			margin-top: 60px;
			font-size: 2.63rem;
			max-width: none;
		}
	}
}
.topPage {
	.container {
		position: relative;
		padding-top: 40px;
		padding-bottom: 40px;
		@media (max-width: @screen-xs-max) {
			padding-top: 90px;
		}
	}
	.addthis_toolbox {
		top: 30px;
	}
}

// Carousel
.carousel {
	.item {
		margin: 0;
	}
	li {
		margin: 1px !important;
		&.active {
			margin: 0 !important;
		}
	}
}


// Paragraphs
.paragraphs-items > div {
	// margin-top: 75px;
	// margin-bottom: 75px;
	margin-top: 30px;
	margin-bottom: 30px;
	&:first-child {
		margin-top: 15px;
	}
	ul {
		.customList;
	}
}
.content-evidence {
	position: relative;
	/* padding-top: 90px;
	padding-bottom: 140px; */
	padding-top: 45px;
	padding-bottom: 70px;
	background-color: #f1f1f1;
	@media (max-width: @screen-xs-max) {
		padding-bottom: 0;
	}
	.imgWrapper {
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 66.66666667%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		@media (max-width: @screen-xs-max) {
			position: relative;
			top: auto;
			right: auto;
			bottom: auto;
			left: auto;
			margin-top: 60px;
			height: 200px;
			width: 100%;
		}
	}
	.content {
		position: relative;
		z-index: 5;
	}
	h2:first-child {
		margin-top: 0;
	}
	.btn {
		margin-top: 10px;
	}
	.btn1 {
		background-color: #252525;
		border-color: #252525;
		color: #fff;
		&:hover {
			background-color: darken(#252525, 15%);
			border-color: darken(#252525, 15%);
		}
	}
	.btn2 {
		padding-left: 30px;
		padding-right: 30px;
		background-color: transparent;
		border-color: #252525;
		color: #252525;
		font-size: 1.13rem;
		line-height: 1.11em;
		&:hover {
			background-color: #252525;
			border-color: #252525;
			color: #fff;
		}
	}
}
.content-focus {
	.content {
		padding-top: 60px;
		padding-bottom: 60px;
		padding-left: 35px;
		color: #b7b7b7;
		font-size: 2rem;
		font-weight: 300;
		line-height: normal;
	}
}
.content-cta {
	.title {
		margin-bottom: -20px;
		text-align: center;
		overflow: hidden;
		h2 {
			display: inline-block;
			position: relative;
			margin: 0;
			padding: 0 50px;
			color: @brand-primary;
			font-weight: 700;
			@media (max-width: @screen-xs-max) {
				padding: 0 15px;
			}
			&:before, &:after {
				content: '';
				position: absolute;
				top: 50%;
				height: 1px;
				width: 9999px;
				background-color: #d3d3d3;
			}
			&:before {
				right: 100%;
			}
			&:after {
				left: 100%;
			}
		}
	}
	.ctas {
		padding-top: 45px;
		padding-bottom: 45px;
		border-top: 1px solid #d3d3d3;
		border-bottom: 1px solid #d3d3d3;
		font-size: 0;
		text-align: center;
		.btn {
			display: inline-block;
			vertical-align: middle;
			margin: 15px;
			width: calc(~"50% - 30px");
			padding-top: 18px;
			padding-bottom: 18px;
			font-weight: 700;
			@media (max-width: @screen-xs-max) {
				width: calc(~"100% - 30px");
			}
		}

	}
	&.titleOn {
		.ctas {
			border-top: 0;
		}
	}
}
.content-avantage {
	.owl-carousel {
		.item {
			margin: 0;
		}
	}
	.owl-prev, .owl-next {
		position: absolute;
		top: 50%;
		margin-top: -22px;
		color: #2a2a2a;
		font-size: 2.81rem;
		line-height: 1em;
		.transition(all .2s);
		&:hover {
			color: @brand-primary;
		}
	}
	.owl-prev {
		left: -100px;
	}
	.owl-next {
		right: -100px;
	}
}
.content-videos {
	.owl-carousel {
		display: block;
		font-size: 0;
		text-align: center;
		> a.item {
			display: inline-block;
			vertical-align: middle;
			margin: 15px;
			width: calc(~"25% - 30px");
			@media (max-width: @screen-md-max) {
				width: calc(~"33.33333333% - 30px");
			}
			@media (max-width: @screen-sm-max) {
				width: calc(~"50% - 30px");
			}
			@media (max-width: @screen-xs-max) {
				width: calc(~"100% - 30px");
			}
		}
	}
}

// Marques
.marques {
	padding-top: 85px;
	padding-bottom: 80px;
	h2 {
		margin: 0 0 25px;
		color: #1a1a18;
		font-weight: 700;
		text-align: center;
	}
	.owl-carousel {
		display: block;
		font-size: 0;
		text-align: center;
		> .item--marque, > a {
			display: inline-block;
			vertical-align: middle;
			margin: 15px 30px;
		}
		.item--marque, a {
			-webkit-filter: grayscale(1);
			filter: grayscale(1);
			.transition(all .2s);
			&:hover {
				-webkit-filter: grayscale(0);
				filter: grayscale(0);
			}
		}
	}
	.owl-prev, .owl-next {
		position: absolute;
		top: 50%;
		margin-top: -20px;
		color: #1a1a18;
		font-size: 2.5rem;
		line-height: 1em;
		.transition(all .2s);
		&:hover {
			color: @brand-primary;
		}
		@media (max-width: @screen-xs-max) {
			position: relative;
			float: left;
			top: auto;
			margin-top: 15px;
		}
	}
	.owl-prev {
		left: -85px;
		@media (max-width: 1349px) {
			left: -22px;
		}
		@media (max-width: @screen-xs-max) {
			left: auto;
		}
	}
	.owl-next {
		right: -85px;
		@media (max-width: 1349px) {
			right: -22px;
		}
		@media (max-width: @screen-xs-max) {
			right: auto;
			margin-left: 20px;
		}
	}
}

// Pager cycle
.cycle-slideshow {
	.pagerWrapper {
		position: absolute;
		z-index: 110;
		bottom: 60px;
		left: 0;
		width: 100%;
	}
}
#custom-pager {
	float: right;
	span {
		display: block;
		float: left;
		height: 10px;
		width: 10px;
		background-color: #fff;
		border: 2px solid #fff;
		border-radius: 50%;
		opacity: .4;
		cursor: pointer;
		text-indent: -9999px;
		.transition(all .2s);
		& + span {
			margin-left: 35px;
		}
		&:hover, &.cycle-pager-active {
			background-color: transparent;
			opacity: 1;
		}
	}
	&.outside {
		position: absolute;
		bottom: 60px;
		right: 15px;
		@media (max-width: @screen-sm-max) {
			display: none;
		}
	}
}


body.page-search {
	 .search-results {
    	li {
    		padding-bottom: 30px;
    		border-bottom: 1px solid #e5e5e5;
    	}
    }
}

#autocomplete {
	border: 1px solid #a7a7a7;
	li {
		margin: 0;
		padding: 5px 10px;
		color: #2a2a2a;
		cursor: pointer;
		.transition(all .2s);
		&:hover {
			background-color: @brand-primary;
			color: #fff;
		}
	}
}

// Animate
.animated {
	.animated;
}
.os-animation {
	opacity: 0;
	&.animated {
		opacity: 1;
	}
	&.custom {
		-webkit-animation-fill-mode: backwards;
	    -moz-animation-fill-mode: backwards;
	    -o-animation-fill-mode: backwards;
	    animation-fill-mode: backwards;
	}
	@media (max-width: @screen-xs-max) {
		-webkit-animation-delay: 0s !important;
		-moz-animation-delay: 0s !important;
		-ms-animation-delay: 0s !important;
		-o-animation-delay: 0s !important;
		animation-delay: 0s !important;
	}
}

body.node-type-page {
	.node-page {
		.paragraphs-items {
			> div {
				&:first-child {
					margin-top: 25px;
				}
			}
		}
	}
}
.lb-nav {
  a.lb-prev {
    background-image: url(../images/prev.png);
  }
  a.lb-next {
    background-image: url(../images/next.png);
  }
}
.lb-cancel {
  background-image: url(../images/loading.gif);
}
.lb-data {
  .lb-close {
    background-image: url(../images/close.png);
  }
}
