// Produit
#other {
	// padding-bottom: 150px;
	padding-bottom: 75px;
	text-align: center;
}
#inspirations {
	.expand-resultsWrapper {
		margin-bottom: 70px;
		text-align: center;
	}
}
#products {
	padding-top: 45px;
	padding-bottom: 25px;
	background-color: #f1f1f1;
	.blockTitle {
		margin-top: 0;
		margin-bottom: 40px;
		color: #1a1a18;
		font-weight: 700;
		text-align: center;
	}
	.expand-resultsWrapper {
		margin-top: 80px;
		text-align: center;
	}
	h2,h3,h4,h5,h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

#conseils {
	padding-top: 50px;
	h2 {
		margin-top: 0;
		margin-bottom: 35px;
		color: #2a2a2a;
		font-weight: 700;
		text-transform: uppercase;
	}
}

.productOverlay {
	.modalContent {
		.productImg {
			@media (min-width: 1350px) {
				position: relative;
				left: -50px;
			}			
		}
		.wrap {
			padding-top: 70px;
			padding-bottom: 70px;
			background-color: #fff;
			> .row {
				margin: 0;
			}
		}
		.imgWrapper {
			img {
				width: 300px;
			}
			@media (max-width: @screen-sm-max) {
				margin-bottom: 60px;
			}
		}
		h3 {
			margin: 0 0 25px;
			max-width: 420px;
			color: #3a3a3a;
			font-size: 1.13rem;
			font-weight: 700;
			line-height: 1.33em;
		}
		p {
			margin: 0 0 40px;
			max-width: 420px;
			color: #3a3a3a;
			font-size: 1rem;
			line-height: 1.5em;
		}
		.table-responsive {
			margin: 0;
		}
		table {
			margin: 0;
			border: 0;
			tr + tr {
				border-top: 1px solid #e5e5e5;
			}
			th {
				padding: 5px;
			}
			td {
				padding: 0 3px;
				background-color: transparent;
				border: 0;
				color: #3a3a3a;
				font-size: 1rem;
			    font-weight: 700;
			    line-height: 1.5em;
			    @media (max-width: @screen-xs-max) {
					padding-left: 10px;
					padding-right: 10px;
				}
			    strong {
			    	color: @brand-primary;
			    }
			    /* &:last-child {
			    	text-align: right;
			    } */
			}
		}
		a.btn {
			margin-top: 30px;
		}
	}
}