// Professionnel
.node-type-professionnels {
  #banner .titleAbsolute .wrap {
    padding-top: 33px;
  }
}
.ctas-prof {
  position: absolute;
  z-index: 200;
  bottom: 0;
  right: 15px;
  width: calc(~"41.66666667% - 15px");
  .transition(all 0.2s);
  @media (max-width: @screen-md-max) {
    width: calc(~"50% - 15px");
  }
  .cta {
    display: block;
    padding: 10px 15px;
    padding-left: 62px;
    width: 100%;
    background-color: @brand-primary;
    background-position: left 15px center;
    background-repeat: no-repeat;
    background-size: 32px auto;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.45;
    text-decoration: none;
    .transition(all 0.2s);
    h3 {
      color: inherit;
      font-size: 1.125rem;
      font-weight: 700;
      line-height: 1.125;
    }
    h3,
    p {
      margin: 0;
    }
    &.commerce {
      background-image: url(../img/picto-cart.png);
    }
    &.catalogue {
      background-image: url(../img/picto-book.png);
    }
    &.inscription {
      background-image: url(../img/picto-inscription.png);
    }
    & + .cta {
      border-top: 1px solid darken(@brand-primary, 10%);
      padding-top: 9px;
    }
    &:hover {
      background-color: darken(@brand-primary, 7.5%);
    }
  }
  @media (max-width: @screen-sm-max) {
    position: fixed;
    top: 110px;
    right: -225px;
    bottom: auto;
    width: 285px;
    .cta {
      position: relative;
      left: 0;
      width: 100%;
      &:hover {
        left: -225px;
      }
    }
  }
  &.scrolled {
    position: fixed;
    top: 110px;
    right: -225px;
    bottom: auto;
    width: 285px;
    .cta {
      position: relative;
      left: 0;
      width: 100%;
      &:hover {
        left: -225px;
      }
    }
  }
}

#prof-highlight {
  padding-top: 75px;
  padding-bottom: 60px;
  h2 {
    margin: 0 0 20px;
    color: #2a2a2a;
    font-weight: 700;
  }
}

#prof-content {
  margin-top: 50px;
  padding: 1px 0;
  background-color: #f1f1f1;
}
