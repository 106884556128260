@-webkit-keyframes swing {
	20%,40%,60%,80%,100% {
		-webkit-transform-origin:top center;
	}
	
	20% {
		-webkit-transform:rotate(15deg);
	}
	
	40% {
		-webkit-transform:rotate(-10deg);
	}
	
	60% {
		-webkit-transform:rotate(5deg);
	}
	
	80% {
		-webkit-transform:rotate(-5deg);
	}
	
	100% {
		-webkit-transform:rotate(0deg);
	}
}

@-moz-keyframes swing {
	20% {
		-moz-transform:rotate(15deg);
	}
	
	40% {
		-moz-transform:rotate(-10deg);
	}
	
	60% {
		-moz-transform:rotate(5deg);
	}
	
	80% {
		-moz-transform:rotate(-5deg);
	}
	
	100% {
		-moz-transform:rotate(0deg);
	}
}

@-ms-keyframes swing {
	20% {
		-ms-transform:rotate(15deg);
	}
	
	40% {
		-ms-transform:rotate(-10deg);
	}
	
	60% {
		-ms-transform:rotate(5deg);
	}
	
	80% {
		-ms-transform:rotate(-5deg);
	}
	
	100% {
		-ms-transform:rotate(0deg);
	}
}

@-o-keyframes swing {
	20% {
		-o-transform:rotate(15deg);
	}
	
	40% {
		-o-transform:rotate(-10deg);
	}
	
	60% {
		-o-transform:rotate(5deg);
	}
	
	80% {
		-o-transform:rotate(-5deg);
	}
	
	100% {
		-o-transform:rotate(0deg);
	}
}

@keyframes swing {
	20% {
		transform:rotate(15deg);
	}
	
	40% {
		transform:rotate(-10deg);
	}
	
	60% {
		transform:rotate(5deg);
	}
	
	80% {
		transform:rotate(-5deg);
	}
	
	100% {
		transform:rotate(0deg);
	}
}

.swing {
	.animation-name(swing);
}