@-webkit-keyframes dropUp {
	0% {
		opacity:1;
		-webkit-transform: translateY(0px);
	}
	
	30% {
		opacity:1;
	}
	
	100% {
		opacity:0;
		-webkit-transform: translateY(-30px);
	}
}

@-moz-keyframes dropUp {
	0% {
		opacity:1;
		-moz-transform: translateY(0px);
	}
	
	30% {
		opacity:1;
	}
	
	100% {
		opacity:0;
		-moz-transform: translateY(-30px);
	}
}

@-ms-keyframes dropUp {
	0% {
		opacity:1;
		-ms-transform: translateY(0px);
	}
	
	30% {
		opacity:1;
	}
	
	100% {
		opacity:0;
		-ms-transform: translateY(-30px);
	}
}

@-o-keyframes dropUp {
	0% {
		opacity:1;
		-o-transform: translateY(0px);
	}
	
	30% {
		opacity:1;
	}
	
	100% {
		opacity:0;
		-o-transform: translateY(-30px);
	}
}

@keyframes dropUp {
	0% {
		opacity:1;
		transform: translateY(0px);
	}
	
	30% {
		opacity:1;
	}
	
	100% {
		opacity:0;
		transform: translateY(-30px);
	}
}

.dropUp {
	.animation-name(dropUp);
}