// Inspiration
body.node-type-inspiration {
    #inspiration {
        .container;
        .md-width;
        .imgWrapper {
            img {
                box-shadow: none;
            }
        }
    }
}