// form
.webform-client-form {
	.form-control, .form-select {
		height: 50px;
		width: 100%;
		padding: 0 15px;
		background-color: #fff;
		border: 1px solid #a7a7a7;
		border-radius: 5px;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
	}
	.form-control, .form-select, output {
		color: #000;
		font-size: 1rem;
		font-weight: 400;
		line-height: normal;
	}
    .form-control.error {
        border-color: @state-danger-border;
        color: @state-danger-text;
    }
	::-webkit-input-placeholder {
      color: #000;
    }
    ::-moz-placeholder {
      color: #000;
    }
    :-ms-input-placeholder {
      color: #000;
    }
    :-moz-placeholder {
      color: #000;
    }
    .url-textfield {
    	display: none;
    }
    .form-item {
    	label {
    		color: #000;
			font-size: 1rem;
			font-weight: 700;
			line-height: normal;
    	}
    	&.radio, &.checkbox {
    		label {
    			font-size: 0.88rem;
    			font-weight: 400;
    		}
    	}
    }
    .form-managed-file {
        font-size: 0;
        input, button {
            display: inline-block;
            vertical-align: middle;
        }
        input {
            border-radius: 5px 0 0 5px;
            width: calc(~"100% - 120px");
        }
        button {
            padding: 0;
            border-radius: 0 5px 5px 0;
            height: 50px;
            width: 120px;
            line-height: 46px;
        }
    }
    .form-file {
        padding-top: 12px;
        padding-bottom: 12px;
    }
    div[id*="edit-submitted-photos"] {
        margin-bottom: 15px;
    }

    fieldset {
        margin-top: 15px;
        margin-bottom: 15px;
        padding-top: 30px;
        padding-bottom: 30px;
        border: 2px solid @brand-primary;
        border-radius: 5px;
        .panel-heading {
            .h3;
            margin: 0 0 20px;
            border: 0;
        }
    }
}

#search-form {
	.input-group {
		.clearfix;
        .input-group {
            float: left;
            width: calc(~"100% - 50px");
        }
	}
    .input-group-addon {
        display: none;
    }
    .form-control {
        padding: 5px 15px;
        height: 50px;
        width: 100%;
        background-color: #fff;
        border: 1px solid #a7a7a7;
        border-radius: 5px 0 0 5px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        outline: 0;
    }
    .form-control, output {
        display: block;
        color: #000;
        font-size: 1rem;
        font-weight: 400;
        line-height: normal;
    }
    ::-webkit-input-placeholder {
      color: #000;
    }
    ::-moz-placeholder {
      color: #000;
    }
    :-ms-input-placeholder {
      color: #000;
    }
    :-moz-placeholder {
      color: #000;
    }
    .input-group-btn {
        float: left;
        display: block;
        .btn {
            display: block;
            padding: 5px;
            height: 50px;
            width: 50px;
            background-color: @brand-primary;
            border: 0;
            border-radius: 0 5px 5px 0;
            color: #fff;
            font-size: 1.13rem;
            font-weight: 400;
            line-height: 1em;
            &:hover {
                background-color: darken(@brand-primary, 15%);
            }
        }
    }
}

html.js input.throbbing {
    background-repeat: no-repeat;
    background-position: right 5px center;
}