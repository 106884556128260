// Home
#leaderboard {
	position: relative;
	overflow: hidden;
}

#slider {
	position: relative;
	z-index: 0;
	height: 630px;
	overflow: hidden;
	.cycle-slideshow {
		height: 630px;
		> .slide {
			height: 630px;
			width: 100%;
			background-color: #000;
			.content {
				position: relative;
				z-index: 1;
				padding: 80px 0;
				@media (max-width: @screen-xs-max) {
					position: absolute;
					bottom: 0;
					left: 0;
					padding: 60px 0;
					width: 100%;
				}
				> .container {
					overflow: hidden;
				}
				.wrapper {
					margin: 0 auto;
					max-width: 970px;
					@media (max-width: @screen-sm-max) {
						padding-left: 25px;
						padding-right: 25px;
					}
				}
				h2 {
					margin: 0 0 25px;
					max-width: 450px;
					color: #fff;
					font-size: 3.25rem;
					font-weight: 700;
					line-height: 1em;
					@media (max-width: @screen-sm-max) {
						margin-bottom: 35px;
					}
				}
				.text {
					padding: 0 40px;
					max-width: 370px;
					color: #fff;
					font-size: 1.13rem;
					line-height: 1.33em;
					@media (max-width: @screen-xs-max) {
						padding: 0;
					}
					p {
						margin: 0 0 25px;
					}
				}
			}
			.bg {
				opacity: .8;
			}
		}
		.pagerWrapper {
			bottom: 190px;
			@media (max-width: @screen-sm-max) {
				display: none;
			}
		}
	}
}

#projet {
	position: absolute;
	z-index: 5;
	bottom: -146px;
	left: 0;
	width: 100%;
	.transition(all .35s);
	@media (max-width: @screen-xs-max) {
		display: none;
	}
	.container {
		background-color: fade(@brand-primary, 65%);
	}
	.wrapper {
		margin: 0 auto;
		max-width: 940px;
	}
	img {
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;
		width: 356px;
	}
	.btn {
		padding: 16px 45px;
		font-size: 1.38rem;
		font-weight: 500;
		line-height: 1.36em;
	}
	.select {
		padding: 45px 0;
		text-align: center;
	}
	.more {
		border-top: 1px solid rgba(255,255,255,.5);
		font-size: 0;
		a {
			display: inline-block;
			vertical-align: middle;
			padding: 95px 30px 30px;
			height: 145px;
			width: 33.33333333%;
			background-position: center top 45px;
            background-repeat: no-repeat;
            background-size: 45px auto;
			color: #fff;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.25em;
			text-align: center;
			text-decoration: none;
			.transition(all .2s);
			&.sdb {
				background-image: url(../img/picto-sdb--hover.png);
			}
			&.chauffage {
				background-image: url(../img/picto-chauffage--hover.png);
			}
			&.cuisine {
				background-image: url(../img/picto-cuisine--hover.png);
			}
			&:hover {
				background-color: #fff;
				color: @brand-primary;
				&.sdb {
					background-image: url(../img/picto-sdb.png);
				}
				&.chauffage {
					background-image: url(../img/picto-chauffage.png);
				}
				&.cuisine {
					background-image: url(../img/picto-cuisine.png);
				}
			}
		}
	}
	&.open {
		bottom: 0;
	}
}

#news {
	.title {
		margin-top: 50px;
		margin-bottom: 35px;
		color: #2a2a2a;
		font-weight: 700;
		text-transform: uppercase;
		@media (max-width: @screen-sm-max) {
			padding-left: 25px;
			padding-right: 25px;
		}
		@media (max-width: @screen-xs-max) {
			margin-top: 30px;
			margin-bottom: 10px;
		}
	}
	.promotions {
		margin-bottom: 20px;
	}
	.news {
		margin-bottom: 40px;
		.more-link {
			margin-left: 7px;
			@media (max-width: @screen-md-max) {
				margin-left: -26px;
			}
			@media (max-width: @screen-sm-max) {
				margin-left: -63px;
			}
			@media (max-width: @screen-xs-max) {
				margin-left: 0;
				padding: 0 15px;
				text-align: center;
			}
		}
	}
}

#inspirations {
	.view-header {
		display: none;
	}
	.blockTitle {
		margin-top: 50px;
		margin-bottom: 25px;
		color: #2a2a2a;
		font-weight: 700;
		text-transform: uppercase;
	}
	&:not(.pi) {
		padding-bottom: 70px;
		background-color: #f1f1f1;
		.blockTitle {
			position: absolute;
			@media (max-width: @screen-md-max) {
				position: relative;
			}
		}
	}
	&.pi {
		&.bg-gray {
			background-color: #f1f1f1;
		}
		.blockTitle {
    		margin-bottom: 30px;
    		padding-bottom: 25px;
    		border-bottom: 1px solid rgba(0,0,0,.1);
		}
	}
}

#realisations {
	padding-top: 10px;
	.block-title {
		float: left;
		margin-top: 50px;
		margin-bottom: 25px;
		color: #2a2a2a;
		font-weight: 700;
		text-transform: uppercase;
		@media (max-width: @screen-md-max) {
			float: none;
		}
	}
	.filters {
		float: right;
		margin: 50px 0 0;
		padding: 0;
		list-style: none;
		@media (max-width: @screen-md-max) {
			margin-top: 0;
		}
		@media (max-width: @screen-xs-max) {
			float: none;
		}
		li {
			float: left;
			@media (max-width: @screen-xs-max) {
				font-size: 0;
			}
			a {
				display: block;
				padding: 10px 0;
				border-bottom: 3px solid transparent;
				color: #2a2a2a;
				font-size: 0.88rem;
				font-weight: 700;
				line-height: 1.43em;
				text-decoration: none;
				text-transform: uppercase;
				.transition(all .2s);
				@media (max-width: @screen-xs-max) {
					display: inline-block;
					border: 0;
				}
				&:hover, &.active {
					border-bottom-color: @brand-primary;
					color: @brand-primary;
				}
			}
			& + li {
				margin-left: 20px;
				@media (max-width: @screen-xs-max) {
					margin-left: 5px;
					&:before {
						content: '-';
						display: inline-block;
						margin-right: 5px;
						color: #2a2a2a;
						font-size: 0.88rem;
						font-weight: 700;
						line-height: 1.43em;
					}
				}
			}
		}
	}
	#indu_conseils {
		clear: both;
		padding-top: 20px;
	}
}
