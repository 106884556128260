// Print
@media print {
	#nav, #searchBox, .navMobile, .addthis_toolbox, .breadcrumb, .moreBtn, .view-filters, .pager-load-more, .topPage, .content-galerie,
	#footer, .viewSearch, .content-cta, .magasinDetails .coordonnees .map, .cycle-slideshow, #other, .expand-resultsWrapper, .owl-nav {
		display: none !important;
	}

	body {
		padding-top: 0 !important;
	}
	#main {
		padding-bottom: 0 !important;
	}
	a[href]:after {
		content: "";
	}
	.os-animation {
		opacity: 1 !important;
	}

	#header {
		position: relative;
		.logo {
			float: none;
			.logoLarge {
				display: block !important;
			}
			.logoMobile {
				display: none !important;
			}
		}
	}
	#banner {
		height: auto !important;
		.titleAbsolute, &.titleOnly .title {
			position: relative !important;
			bottom: auto !important;
			left: auto !important;
		}
		&.titleOnly .title {
			margin-top: 30px;
		}
		.bg {
			display: none !important;
		}
	}
	.viewTitle {
		padding-top: 0;
	}
	.item--contentFloat {
		.content {
			position: relative;
			top: auto;
			left: auto;
			margin: 0 0 15px;
			padding: 0;
			p {
				display: block !important;
			}
		}
		.imgWrapper {
			padding: 0 !important;
			width: 300px !important;
		}
	}
	.item {
		.imgWrapper {
			width: 300px !important;
		}
	}
	.item--label {
		margin: 0 !important;
		padding: 0 !important;
	}
	.item--price {
		.imgWrapper {
			border: 0;
		}
		.magasins {
			position: relative !important;
			bottom: auto !important;
			left: auto !important;
		}
		.title {
			float: none !important;
			padding: 0 !important;
			border: 0 !important;
			width: auto !important;
		}
		.price {
			float: none !important;
			padding: 0 !important;
			min-height: 0 !important;
			width: auto !important;
			text-align: left !important;
		}
	}
	.content-texte {
		img {
			width: 300px;
		}
	}
	.inspirationMasonry .inspirationShow img {
		width: 300px;
	}
}